<template>
  <div class="homeReport-list">
    <b-tabs content-class="tabWhite">
      <b-tab active class="upcoming">
        <div class="scrollSection" :style="'height:' + scrollHeight" :class="{mobilescroll : mobWidget == 'Y'}">
          <template v-if="upComing.aus[0] == '' && upComing.wpg[0] == '' && upComing.all[0] == '' && upComing.snr[0] == ''">
            <span class="noUp-text">No Upcoming Tournaments</span>
          </template>
          <template v-else>
            <!-- pga -->
            <b-row
              v-for="(upC, index) in upComing.aus[0]"
              :key="index + Math.random()"
              class="rowStyle"
            >
              <a href="https://aus.cw.au.dotgolf.com.au/pga-tour-aus" class="linkWidth" target="_parent">
                <b-col class="eachItem">
                  <b-nav-item
                    :href="'https://aus.cw.au.dotgolf.com.au/pga-tour-aus'"
                    target="_parent"
                  >
                    <p class="tourName">
                      {{ upC.short_name }}
                    </p>
                  </b-nav-item>
                  <p v-if="upC.days_away === '0'" class=" tourLink">
                    Tour | Today
                  </p>
                  <p v-else-if="upC.days_away === '1'" class="tourLink">
                    Tour | Tomorrow
                  </p>
                  <p v-else class="tourLink">Tour | {{ upC.prix_dates }}</p>
                </b-col>
                <b-col>
                  <b-nav-item
                    :href="'https://aus.cw.au.dotgolf.com.au/pga-tour-aus'"
                    class="angleLink"
                    target="_parent"
                  >
                    <img class="Rightarrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/07/chevron-right.png">
                  </b-nav-item>
                </b-col>
              </a>
            </b-row>
            <!-- wpga -->
            <b-row
              v-for="(upC, index) in upComing.wpg[0]"
              :key="index + Math.random()"
              class="rowStyle"
            >
              <a href="https://aus.cw.au.dotgolf.com.au/wpga-tour-of-aus" class="linkWidth" target="_parent">
                <b-col class="eachItem">
                  <b-nav-item
                    :href="'https://aus.cw.au.dotgolf.com.au/wpga-tour-of-aus'"
                  >
                    <p class="tourName">
                      {{ upC.short_name }}
                    </p>
                  </b-nav-item>
                  <p v-if="upC.days_away === '0'" class=" tourLink">
                    WPGA Tour | Today
                  </p>
                  <p v-else-if="upC.days_away === '1'" class="tourLink">
                    WPGA Tour | Tomorrow
                  </p>
                  <p v-else class="tourLink">Tour | {{ upC.prix_dates }}</p>
                </b-col>
                <b-col>
                  <b-nav-item
                    :href="'https://aus.cw.au.dotgolf.com.au/wpga-tour-of-aus'"
                    class="angleLink"
                    target="_parent"
                  >
                    <img class="Rightarrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/07/chevron-right.png">
                  </b-nav-item>
                </b-col>
              </a>
            </b-row>
            <!-- pro-am -->
            <b-row
              v-for="(upC, index) in upComing.all[0]"
              :key="index + Math.random()"
              class="rowStyle"
            >
              <a href="https://aus.cw.au.dotgolf.com.au/pga-pro-am-series" class="linkWidth" target="_parent">
                <b-col class="eachItem">
                  <b-nav-item
                    :href="'https://aus.cw.au.dotgolf.com.au/pga-pro-am-series'"
                    target="_parent"
                  >
                    <p class="tourName">
                      {{ upC.short_name }}
                    </p>
                  </b-nav-item>
                  <p v-if="upC.days_away === '0'" class="tourLink">
                    Pro-Am | Today
                  </p>
                  <p v-else-if="upC.days_away === '1'" class="tourLink">
                    Pro-Am | Tomorrow
                  </p>
                  <p v-else class="tourLink">Pro-Am | {{ upC.prix_dates }} </p>
                </b-col>
                <b-col>
                  <b-nav-item
                    :href="'https://aus.cw.au.dotgolf.com.au/pga-pro-am-series'"
                    class="angleLink"
                    target="_parent"
                  >
                    <img class="Rightarrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/07/chevron-right.png">
                  </b-nav-item>
                </b-col>
              </a>
            </b-row>
            <!-- legends -->
            <b-row
              v-for="(upC, index) in upComing.snr[0]"
              :key="index + Math.random()"
              class="rowStyle"
            >
              <a href="https://aus.cw.au.dotgolf.com.au/pga-legends-tour" class="linkWidth" target="_parent">
                <b-col class="eachItem">
                  <b-nav-item
                    :href="'https://aus.cw.au.dotgolf.com.au/pga-legends-tour'"
                    target="_parent"
                  >
                    <p class="tourName">
                      {{ upC.short_name }}
                    </p>
                  </b-nav-item>
                  <p v-if="upC.days_away === '0'" class="tourLink">
                    Legends | Today
                  </p>
                  <p v-else-if="upC.days_away === '1'" class="tourLink">
                    Legends | Tomorrow
                  </p>
                  <p v-else class="tourLink">Legends | {{ upC.prix_dates }} </p>
                </b-col>
                <b-col>
                  <b-nav-item
                    :href="'https://aus.cw.au.dotgolf.com.au/pga-legends-tour'"
                    class="angleLink"
                    target="_parent"
                  >
                    <img class="Rightarrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/07/chevron-right.png">
                  </b-nav-item>
                </b-col>
              </a>
            </b-row>
          </template>
          <!-- lower links -->
        </div>
        <b-row>
          <!-- <b-col lg="4" cols="4"></b-col> -->
          <b-col cols="1">
          </b-col>
          <b-col cols="11" class="nopadRight">
            <b-row class="linktourRow">
              <b-col class="upPadRight">
                <b-nav-item
                  class="LinkReport"
                  :href="'https://aus.cw.au.dotgolf.com.au/pga-tour-aus'"
                  target="_parent"
                >
                  <p class="quickLink">
                    PGATA
                    <img class="rightLittleArrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/07/chevron-right-copy.png">
                  </p>
                </b-nav-item>
              </b-col>
              <b-col class="upPadRight">
                <b-nav-item
                  class="LinkReport"
                  :href="'https://aus.cw.au.dotgolf.com.au/wpga-tour-aus'"
                  target="_parent"
                >
                  <p class="quickLink">
                    WPGA
                    <img class="rightLittleArrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/07/chevron-right-copy.png">
                  </p>
                </b-nav-item>
              </b-col>
              <b-col class="upPadRight">
                <b-nav-item
                  class="LinkReport"
                  :href="'https://aus.cw.au.dotgolf.com.au/pga-legends-tour'"
                  target="_parent"
                >
                  <p class="quickLink">
                    Legends
                    <img class="rightLittleArrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/07/chevron-right-copy.png">
                  </p>
                </b-nav-item>
              </b-col>
              <b-col class="">
                <b-nav-item
                  class="LinkReport"
                  :href="'https://aus.cw.au.dotgolf.com.au/pga-pro-am-series'"
                  target="_parent"
                >
                  <p class="quickLink">
                    Pro-Am
                    <img class="rightLittleArrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/07/chevron-right-copy.png">
                  </p>
                </b-nav-item>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "homeReportSelect",
  props: ["season"],
  data() {
    return {
      upComing: [],
      tm_params: [],
      mobWidget: process.env.VUE_APP_MOB_WIDGET,
      text: '',
      scrollHeight: ''
    };
  },
  methods: {
    myFunction: function() {
      let h = window.outerWidth;
      let txt = h;
      this.text = txt;
      if (this.text == 1400 || this.text > 1401) {
        this.scrollHeight = '555px!important'
      } else if(this.text < 1400  && this.text > 1200) {
        this.scrollHeight = '442px!important'
      } else if(this.text > 990 && this.text < 1199) {
        this.scrollHeight = '357px!important'
      }
    },
  },
  created() {
    this.myFunction();
    window.addEventListener('resize', this.myFunction);
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE+process.env.VUE_APP_AUS+"/tmticx?field=Y&fields=code&fields=tm_params.season_code&fields=ts_final_result&fields=scores_available&fields=entries_avail&fields=reports.reports_entry&randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        (this.tm_params = response.data), (this.id = response.data);
        return axios.get(
          "https://api.aus.ocs-software.com/pgaaus/cache/aus/" +
            this.tm_params.tm_params.season_code +
            "/tmticx?multi=Y&res=N&tour=snr&tour=aus&tour=all&tour=wpg&field=Y&fields=tournaments.tournaments_entry&randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.upComing = response.data;
        localStorage.setItem("aus", JSON.stringify(this.upComing.aus[0]));
        localStorage.setItem("wpg", JSON.stringify(this.upComing.wpg[0]));
        localStorage.setItem("snr", JSON.stringify(this.upComing.snr[0]));
        localStorage.setItem("all", JSON.stringify(this.upComing.all[0]));
      });
  },
};
</script>

<style scoped>
.noUp-text {
  text-align: center;
  display: block;
  margin-top: 100px;
  font-size: 21pt;
}
.scrollSection {
  overflow: hidden;
  height: 555px;
  overflow-x: hidden;
}
.scrollSection::-webkit-scrollbar {
  display: none; 
}
.scrollSection {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.nopadRight {
  padding-right: 0;
}
.upPadRight.col {
  padding-right: 0;
}
a.linkWidth {
  width: 100%;
  display: inline-flex;
}
a.linkWidth:hover {
  text-decoration: none;
}
img.rightLittleArrow {
  margin-left: 5px;
  margin-top: -1px;
}
img.Rightarrow {
  float: right;
  /* margin-right: 40px; */
  margin-top: 23px;
}
li.nav-item.angleLink > a {
  padding: 0;
  color: #000000!important;
}
svg.iconLink.svg-inline--fa.fa-angle-right.fa-w-8 {
  /* height: 58px; */
  width: 23px;
  float: right;
  margin-right: 30px;
  margin-top: 25px;
}
.rowStyle {
  border-bottom: 1px solid #00000033;
}
::v-deep .nav-tabs .nav-link:hover {
  display: none;
}
::v-deep .nav-tabs .nav-link.active {
  display: none;
}
::v-deep .nav-tabs .nav-link {
  cursor: default;
  background-color: #e6e6e6;
  color: #00433e !important;
  border-bottom-color: transparent;
  border-radius: 3px 3px 0px 0px !important;
  height: 38px;
  font-family: "proxima-nova, sans-serif", sans-serif !important;
  font-weight: 600;
  font-style: normal;
  font-size: 9pt;
}
li {
  list-style-type: none;
}
.quickLink {
  color: #003C52;
  font-size: 14px;
  font-weight: 600;
}

::v-deep .nav-item > a {
  color: white;
}
::v-deep .eachItem > li > a {
  padding-left: 0px;
  padding-bottom: 2px;
  padding-top: 25px;
}
.tourName {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  line-height: 0;
  padding-left: 0px;
  margin-bottom: 5px;
}

.tourLink {
  font-size: 12px;
  color: #003C52;
  font-weight: 600;
  display: block;
  line-height: 26px;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* border-bottom: 1px solid #00000033; */
}
::v-deep .tourLink:hover {
  font-size: 12px;
  color: #003C52;
  font-weight: 600;
  display: block;
  line-height: 26px;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* border-bottom: 1px solid #00000033; */
}
::v-deep .tab-content {
  background-color: white;
}
::v-deep .tabWhite {
  padding-top: 0em;
  height: 581px;
  padding-left: 20px;
  padding-right: 20px;
}
.eachItem {
  /* padding: 0px 30px; */
  min-width: 70%;
}
::v-deep li.nav-item.LinkReport > a.nav-link {
  padding-left: 15px;
  padding: 0;
}
::v-deep li.nav-item.LinkReport {
  text-align: right;
}
.linktourRow {
  margin: auto;
  padding-top: 25px;
  margin-right: 10px;
}
.mobilescroll {
  overflow: scroll;
  height: 338px!important;
}
@media only screen and (max-width: 1400px) {
  .eachItem {
    min-width: 85%;
  }
  img.rightLittleArrow {
    margin-left: 0px;
    position: absolute;
    top: 7px;
    padding-left: 6px;
  }
  .tabWhite {
    padding-top: 0;
    height: 181px!important;
  }
}
@media only screen and (max-width: 1200px) {
  .tourName {
      font-size: 14px;
      font-weight: 600;
      color: #000;
      line-height: .9;
      padding-left: 0;
      margin-bottom: 5px;
  }
  .tabWhite {
    padding-top: 0;
    height: 181px!important;
  }
}
@media only screen and (max-width: 1024px) {
  ::v-deep .tabWhite {
    padding-top: 0em;
    height: inherit;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 400px) {
  .linktourRow {
    margin: auto;
    padding-top: 20px;
    margin-right: -6px;
  }
}
</style>